import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`This service provides a GraphQL API for fetching/streaming live vehicle-positions.`}</p>
    <p>{`The API is explorable in our GraphQL IDE: `}<a parentName="p" {...{
        "href": "https://api.entur.io/graphql-explorer/vehicles-v2"
      }}>{`https://api.entur.io/graphql-explorer/vehicles-v2`}</a>{`.`}</p>
    <h2 {...{
      "id": "query"
    }}>{`Query`}</h2>
    <p>{`Enables fetching vehicle-positions in a GraphQL API.`}</p>
    <p><strong parentName="p">{`Base URL:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v2/vehicles/graphql"
      }}>{`https://api.entur.io/realtime/v2/vehicles/graphql`}</a></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  vehicles(codespaceId:"ATB") {
    lastUpdated
    location {
      latitude
      longitude
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "subscription"
    }}>{`Subscription`}</h2>
    <p>{`Enables creating a GraphQL-subscription that will open a websocket and let the server stream all updates to the client continuously.`}</p>
    <p><strong parentName="p">{`Base URL:`}</strong>{` wss://api.entur.io/realtime/v2/vehicles/subscriptions`}</p>
    <p>{`(Uses the websocket-protocol `}<inlineCode parentName="p">{`graphql-ws`}</inlineCode>{`)`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`subscription {
  vehicles(codespaceId:"ATB") {
    lastUpdated
    location {
      latitude
      longitude
    }
  }
}
`}</code></pre>
    <p>{`More details about GraphQL-subscriptions: `}<a parentName="p" {...{
        "href": "https://graphql.org/blog/subscriptions-in-graphql-and-relay/"
      }}>{`https://graphql.org/blog/subscriptions-in-graphql-and-relay/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      